const quoteTranslations = {
  quoteText1: "Fecha de Entrega:",
  quoteText2: "Destino:",
  quoteText3: "Abrir mapa",
  quoteText4: "¿Ha cambiado la cotización de abajo? Envíe un correo a",
  quoteText6: "Su cotización",
  quoteText7: "por",
  quoteText8: "cada uno",
  quoteText9: "Subtotal:",
  quoteText10: "Sus Términos de Entrega",
  quoteText11: "Entrega Tarifa Fija",
  quoteText12: "Entrega:",
  quoteText13: "Tarifa de Entrega Porcentaje del Pedido",
  quoteText14: "Pedido mínimo para entrega:",
  quoteText15: "Volver",

  noBidQuoteText1: "Sin oferta",
  noBidQuoteText2: "Se envió una cotización sin oferta para esta solicitud. Por favor contacte a Heather en",
  noBidQuoteText3: "para asistencia.",

  quoteQueryErrorText1: "Error",
  quoteQueryErrorText2: "Ocurrió un error al cargar su cotización, asegúrese de tener conexión a internet o contacte a Heather en",
  quoteQueryErrorText3: "Ocurrió un error al cargar su cotización, contacte a Heather en",
  quoteQueryErrorText4: "para asistencia.",
  quoteQueryErrorText5: "Volver",
  quoteQueryErrorText6: "Intentar de nuevo",
};

export default quoteTranslations; 