const photosTranslations = {
  mangePhotosText1: "Confirmación de Foto Para",
  mangePhotosText2: "Gestionar fotos para",
  mangePhotosText3: "Eliminar",
  mangePhotosText4: "Subir foto",
  mangePhotosText5: "Ingresar Especificaciones",
  mangePhotosText6: "Ancho",
  mangePhotosText7: "Se requiere un ancho válido en pies",
  mangePhotosText8: "Se requiere un ancho válido en pulgadas",
  mangePhotosText9: "Alto",
  mangePhotosText10: "Se requiere una altura válida en pies",
  mangePhotosText11: "Se requiere una altura válida en pulgadas",
  mangePhotosText12: "Enviar al cliente",
  mangePhotosText13: "Foto",
  mangePhotosText14: "¿Realmente desea eliminar esta imagen?",
  mangePhotosText15: "Eliminar",
  mangePhotosText16: "Volver",

  useDeleteProductPhotoText1: "Eliminado exitosamente",
  useDeleteProductPhotoText2: "La foto del producto ha sido eliminada exitosamente",
  useDeleteProductPhotoText3: "Error al Eliminar",
  useDeleteProductPhotoText4: "Ocurrió un error al eliminar la foto",

  useSubmitPhotoConfirmationText1: "¡Enviado!",
  useSubmitPhotoConfirmationText2: "Foto(s) enviada(s) al cliente exitosamente",
  useSubmitPhotoConfirmationText3: "Error",
  useSubmitPhotoConfirmationText4: "Ocurrió un error al enviar las fotos",

  useUploadProductPhotoText1: "Subida Exitosa",
  useUploadProductPhotoText2: "La foto del producto ha sido subida exitosamente",
  useUploadProductPhotoText3: "Error al Subir",
  useUploadProductPhotoText4: "Ocurrió un error al subir la foto",

  photosQueryErrorText1: "Error",
  photosQueryErrorText2: "Ocurrió un error al obtener la solicitud, asegúrese de tener conexión a internet o contacte a William en:",
  photosQueryErrorText3: "Ocurrió un error al obtener la solicitud, por favor contacte a William en:",
  photosQueryErrorText4: "para ayuda",
  photosQueryErrorText5: "Volver",
  photosQueryErrorText6: "Intentar de nuevo",
};

export default photosTranslations; 