import biddingTranslations from "./bidding";
import browseTranslations from "./browse";
import categoryListingBase from "./categoryListingBase";
import dashboardTranslations from "./dashboard";
import inventoryTranslation from "./inventory";
import landingTranslation from "./landing";
import orderTranslation from "./order";
import photosTranslations from "./photos";
import plantListingBase from "./plantListingBase";
import quoteTranslations from "./quote";
import requestsTranslations from "./requests";
import wrapperTranslations from "./wrapper";

const TRANSLATIONS_ES = {
  ...orderTranslation,
  ...photosTranslations,
  ...landingTranslation,
  ...wrapperTranslations,
  ...quoteTranslations,
  ...dashboardTranslations,
  ...biddingTranslations,
  ...inventoryTranslation,
  ...plantListingBase,
  ...categoryListingBase,
  ...browseTranslations,
  ...requestsTranslations,
};

export default TRANSLATIONS_ES; 