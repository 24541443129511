const browseTranslations = {
  plantDetailsText1: "Frecuentemente comprado con",

  plantDetailsAddToCartText1: "Agregar a",
  plantDetailsAddToCartText2: "Esta planta y tamaño ya está en su proyecto, hacer clic en el botón de arriba solo actualizará la cantidad.",
  plantDetailsAddToCartText3: "Ver carrito",
  plantDetailsAddToCartText4: "Agregar al carrito",
  plantDetailsAddToCartText5: "Para ordenar tamaños no mostrados aquí, por favor envíe un correo a",

  plantDetailsLocationInformationText1: "Ubicación",

  plantDetailsQueryError1: "Ocurrió un error al cargar la planta, asegúrese de tener conexión a internet o contacte a Jules en",
  plantDetailsQueryError2: "Ocurrió un error al cargar la planta, contacte a Jules en",
  plantDetailsQueryError3: "para asistencia.",
  plantDetailsQueryError4: "Intentar de nuevo",

  plantDetailsPlantSizePhotosText1: "No hay fotos disponibles para la combinación de tamaño y planta seleccionada.",

  categoriesListingText1: "Explorar categorías",

  plantsListingText1: "Ver todas las categorías",
};

export default browseTranslations; 