const dashboardTranslations = {
  openOrdersText1: "Pedido #",
  openOrdersText2: "está programado para",
  openOrdersText3: "Por determinar (entrega por fases)",
  openOrdersText4: "Ver",
  openOrdersText5: "Sin pedidos abiertos",
  openOrdersText6: "Actualmente no tiene solicitudes de cotización pendientes. ¡Las nuevas solicitudes de pedido aparecerán una vez recibidas!",
  openOrdersText7: "Pedidos Abiertos",

  photoRequestsText1: "Solicitud #",
  photoRequestsText2: "para",
  photoRequestsText3: "Ver",
  photoRequestsText4: "Sin solicitudes de fotos",
  photoRequestsText5: "Actualmente no tiene solicitudes de fotos pendientes. ¡Las nuevas solicitudes de fotos aparecerán una vez recibidas!",
  photoRequestsText6: "Solicitudes de Fotos",

  quoteRequestsText1: "Nuevo",
  quoteRequestsText2: "Ver",
  quoteRequestsText3: "Sin solicitudes de cotización",
  quoteRequestsText4: "Actualmente no tiene solicitudes de cotización pendientes. Las nuevas solicitudes aparecerán una vez recibidas.",

  submittedQuotesText1: "enviado el",
  submittedQuotesText2: "Ver",
  submittedQuotesText3: "¡Sin cotizaciones enviadas!",
  submittedQuotesText4: "Actualmente no tiene cotizaciones enviadas. Por favor, revise sus solicitudes de cotización pendientes para ofertar.",

  dashboardHeaderText1: "Panel",
  dashboardHeaderText2: "¿Necesita material vegetal al costo?",
  dashboardHeaderText3: "Explorar inventario nacional de viveros",

  quotesPanelText1: "Solicitudes de Cotización",
  quotesPanelText2: "Solicitudes pendientes",
  quotesPanelText3: "Solicitudes enviadas",
};

export default dashboardTranslations; 