const wrapperTranslations = {
  footerText1: `Copyright 2015-${new Date().getFullYear()} Bidscape, Inc`,
  footerText2: "Privacidad",
  footerText3: "Términos",

  headerText1: "Iniciar sesión",
  headerText2: "Cambiar vivero",
  headerText3: "Cerrar sesión",
  headerText4: "Navegación",
  headerText5: "Cambiar tema",
  headerText6: "Cambiar Vivero",
  headerText7: "Cerrar",
  headerText8: "Panel",
  headerText9: "Gestionar Inventario",
  headerText10: "Cambiar idioma",

  switchYardText1: "Buscar vivero",
};

export default wrapperTranslations; 