import { Drawer, NavLink, ScrollArea } from "@mantine/core";
import { IconBuilding, IconHome, IconLogout, IconMoon, IconSun, IconSwitchHorizontal, IconLanguage } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Conditional from "../../../components/Conditional";
import useAppSettings from "../../../hooks/useAppSettings";
import useAuth from "../../../hooks/useAuth";


type Props = {
  opened: boolean;
  onClose: () => void;
  openSwitchYardModal: () => void;
};

const AppDrawer: React.FC<Props> = ({ opened, onClose, openSwitchYardModal }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const appSettings = useAppSettings();
  const { activeYard, isLoggedIn, loggedInUser, logout } = useAuth();

  return (
    <Drawer
      size="xl"
      padding="xl"
      position="right"
      opened={opened}
      onClose={onClose}
      title={activeYard?.firm?.name || t("headerText4")}
    >
      <ScrollArea>
        {/* Items that show when not logged in */}
        <Conditional renderIf={!isLoggedIn}>
          <NavLink
            label={t("headerText1")}
            onClick={() => navigate("/login")}
            leftSection={<IconSwitchHorizontal size={16} strokeWidth={1.5} />}
          />
        </Conditional>

        {/* Items that show when logged in (any user) */}
        <Conditional renderIf={isLoggedIn}>
          <NavLink 
            component="a" 
            onClick={() => navigate("/")} 
            label={t("headerText8")} 
            leftSection={<IconHome color="green" />} 
          />
          <NavLink 
            component="a" 
            onClick={() => navigate("/")} 
            label={t("headerText9")} 
            leftSection={<IconBuilding color="green" />} 
          />
        </Conditional>

        {/* Admin-only items */}
        <Conditional renderIf={isLoggedIn && loggedInUser?.isAdmin}>
          <NavLink
            label={t("headerText2")}
            onClick={openSwitchYardModal}
            leftSection={<IconSwitchHorizontal size={16} strokeWidth={1.5} />}
          />
        </Conditional>

        {/* Items that show for everyone */}
        <NavLink
          label={t("headerText5")}
          onClick={appSettings.toggleTheme}
          leftSection={appSettings.darkMode ? 
            <IconSun size={16} strokeWidth={1.5} /> : 
            <IconMoon size={16} strokeWidth={1.5} />
          }
        />
        <NavLink
          label={`${t("headerText10")} (${i18n.language.split('-')[0].toUpperCase()} → ${i18n.language === 'en' ? 'ES' : 'EN'})`}
          onClick={() => {
            const newLang = i18n.language === 'en' ? 'es' : 'en';
            i18n.changeLanguage(newLang);
          }}
          leftSection={<IconLanguage size={16} strokeWidth={1.5} />}
        />

        {/* Logout - shows only when logged in */}
        <Conditional renderIf={isLoggedIn}>
          <NavLink
            c="red"
            label={t("headerText3")}
            onClick={logout}
            leftSection={<IconLogout size={16} strokeWidth={1.5} />}
          />
        </Conditional>
      </ScrollArea>
    </Drawer>
  );
};

export default AppDrawer;
