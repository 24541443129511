const landingTranslation = {
  loginText1: "Correo de Verificación",
  loginText2: "Para proteger su cuenta, necesitamos enviarle un correo electrónico con un enlace para finalizar su inicio de sesión.",
  loginText3: "Correo electrónico",
  loginText4: "Se requiere un correo electrónico válido",
  loginText5: "Obtener Verificación por Correo",
  loginText6: "Ayuda",
  loginText7: "Cómo terminar de iniciar sesión",
  loginText8: "Ingrese su correo electrónico para recibir un enlace mágico que le permitirá continuar automáticamente después de hacer clic en él. Este paso adicional ayuda a proteger su cuenta de accesos no autorizados y puede ocurrir de vez en cuando o si accede a nuestro sitio desde diferentes dispositivos.",
  loginText9: "Cerrar",

  authenticateLinkText1: "Autenticando enlace",
  authenticateLinkText2: "Por favor espere mientras verificamos su enlace para iniciar sesión.",

  useLinkAuthenticatorText1: "Error",
  useLinkAuthenticatorText2: "Error al autenticar el enlace",

  useLoginText1: "¡Correo enviado!",
  useLoginText2: "¡Correo enviado, revise su bandeja de entrada para continuar!",
  useLoginText3: "Error",
  useLoginText4: "¡Ocurrió un error al enviar el enlace, por favor intente de nuevo!",

  linkSent0: "Enlace de inicio de sesión enviado exitosamente",
  linkSent1: "Revise su correo electrónico,",
  linkSent2: "busque en todas las carpetas de correo",
  linkSent3: "Le enviamos un correo electrónico con un enlace para el paso final. Debería estar en su bandeja de entrada, pero si no lo ve, revise sus carpetas de correo no deseado o spam.",
  linkSent4: "Busque un correo con el asunto:",
  linkSent5: "Complete su inicio de sesión en Bidscape",
  linkSent6: "¿No lo encuentra? Envíe un correo a",
  linkSent7: "para obtener ayuda.",
  linkSent8: "Volver al inicio de sesión",
};

export default landingTranslation; 