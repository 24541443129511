const publicRequest = {
  requestsText1: "Gestión del Carrito",
  requestsText2: "Elija sus plantas",
  requestsText3: "Información del proyecto",
  requestsText4: "Cuéntenos sobre su proyecto",
  requestsText5: "Obtener estimación",
  requestsText6: "Ver precios de plantas, disponibilidad y fotos",

  requestPlantEntryItemText1: "Eliminar",

  cartItemsText1: "Gestión del Carrito",
  cartItemsText2: "Elija sus plantas y especificaciones de tamaño",
  cartItemsText3: "Agregar planta",
  cartItemsText4: "Planta:",
  cartItemsText5: "Tamaño:",
  cartItemsText6: "Cantidad:",
  cartItemsText8: "Obtener Estimación",
  cartItemsText9: "Volver a Categorías",
  cartItemsText10: "Agregar más artículos",

  requestRequestSuccessText1: "¡Su solicitud ha sido enviada exitosamente!",
  requestRequestSuccessText2: "Le enviaremos una estimación dentro de 24 horas a su correo con precios de plantas, disponibilidad y fotos.",
  requestRequestSuccessText3: "Panel",
  requestRequestSuccessText4: "Iniciar sesión",
  requestRequestSuccessText5: "Continuar Comprando",

  requestProjectInfoText1: "Información del Proyecto",
  requestProjectInfoText2: "Proporcione información sobre su proyecto para que podamos optimizar la entrega.",
  requestProjectInfoText3: "Nombre del proyecto",
  requestProjectInfoText4: "Nombre para referirse a este proyecto",
  requestProjectInfoText5: "Se requiere un nombre de proyecto",
  requestProjectInfoText6: "Fases de entrega",
  requestProjectInfoText7: "Las entregas serán realizadas por fases del proyecto",
  requestProjectInfoText8: "Fecha de entrega",
  requestProjectInfoText9: "Fecha de entrega inválida",
  requestProjectInfoText10: "Dirección de entrega",
  requestProjectInfoText11: "La dirección para entrega (incluyendo ciudad, estado y código postal)",
  requestProjectInfoText12: "Se requiere una dirección válida",
  requestProjectInfoText13: "Anterior",
  requestProjectInfoText14: "Finalizar",
  requestProjectInfoText15: "Correo para recibir estimación",
  requestProjectInfoText16: "La dirección de correo donde enviaremos su estimación",
  requestProjectInfoText17: "Se requiere un correo válido",

  requestSelectPlantItemText1: "Seleccionar",

  useSubmitRfqText1: "Éxito",
  useSubmitRfqText2: "Su solicitud ha sido enviada exitosamente",
  useSubmitRfqText3: "Error",
  useSubmitRfqText4: "No se pudo enviar la solicitud. Envíe un correo a william@bidscape.com para ayuda.",
};

export default publicRequest; 