const orderTranslation = {
  frequentlyAskedQuestionsText1: "Preguntas Frecuentes",
  frequentlyAskedQuestionsText2: "Tengo un problema con la entrega del pedido...",
  frequentlyAskedQuestionsText3: "Por favor contacte a Bidscape para recibir asistencia.",
  frequentlyAskedQuestionsText4: "Algunos de los artículos del pedido que garanticé ya no están en inventario...",
  frequentlyAskedQuestionsText5: "Por favor contacte a Bidscape lo antes posible para informarnos de cualquier artículo que faltará en el pedido.",
  frequentlyAskedQuestionsText6: "El cliente está rechazando un artículo por calidad o debido a un error...",
  frequentlyAskedQuestionsText7: "En caso de error, por favor organice un intercambio por el producto correcto o notifique a Bidscape para emitir reembolsos.",
  frequentlyAskedQuestionsText8: "El cliente me está contactando DESPUÉS de que la entrega ya se realizó para solicitar una devolución o intercambio de uno o varios artículos?",
  frequentlyAskedQuestionsText9: "Bidscape no permite devoluciones o intercambios después de que la entrega ya se haya realizado.",

  orderText1: "Fecha de Entrega:",
  orderText2: "Por determinar (entrega por fases)",
  orderText3: "Fecha de Recogida:",
  orderText4: "Destino:",
  orderText5: "Para el pago, por favor contacte a",
  orderText6: "***Por favor no entregue un recibo o precios al cliente. Solo boleta de entrega.***",
  orderText7: "Artículos del Pedido",
  orderText8: "cada uno",
  orderText9: "Subtotal:",
  orderText10: "Cargo de Entrega:",
  orderText11: "Total:",
  orderText12: "Volver",
  orderText13: "Foto",
  orderText14: "Notas del Pedido:",

  orderQueryErrorText1: "Ocurrió un error al cargar su pedido, asegúrese de tener conexión a internet o contacte a Heather en",
  orderQueryErrorText2: "Ocurrió un error al cargar su pedido, contacte a Heather en",
  orderQueryErrorText3: "para asistencia.",
  orderQueryErrorText4: "Volver",
  orderQueryErrorText5: "Intentar de nuevo",
};

export default orderTranslation; 