import {
  ActionIcon,
  Anchor,
  Burger,
  Button,
  Center,
  Divider,
  Group,
  Image,
  Menu,
  Space,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { IconChevronDown, IconLogout, IconMoon, IconSun, IconSwitchHorizontal } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/bidscape.png";

import Conditional from "../../../components/Conditional";
import CustomModal from "../../../components/CustomModal";
import useAppSettings from "../../../hooks/useAppSettings";
import useAuth from "../../../hooks/useAuth";
import useModal from "../../../hooks/useModal";
import SwitchYard from "../components/SwitchYard";
import useAppHeader from "../hooks/useAppHeader";
import classes from "../styles/index.module.css";
import AppDrawer from "./AppDrawer";

const AppHeader: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const switchYardModal = useModal();
  const appSettings = useAppSettings();
  const { drawerOpened, toggleDrawer } = useAppHeader();
  const { activeYard, isLoggedIn, loggedInUser, logout } = useAuth();

  return (
    <>
      <header className={classes.header}>
        <Group px={8} justify="space-between" w="100%" h={56}>
          <Anchor
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            <Image className={classes.logo} src={logo} alt="Bidscape" />
          </Anchor>
          <Conditional renderIf={isLoggedIn}>
            <Group visibleFrom="sm" gap={0}>
              <Button px="8px" variant="subtle" onClick={() => navigate("/")}>
                {t("headerText8")}
              </Button>
              <Button px="8px" variant="subtle" onClick={() => navigate("/inventory")}>
                {t("headerText9")}
              </Button>
            </Group>
          </Conditional>
          <Group visibleFrom="sm">
            <Conditional renderIf={!isLoggedIn}>
              <Button data-testid="login-btn" variant="outline">
                {t("headerText1")}
              </Button>
            </Conditional>
            <Conditional renderIf={isLoggedIn && loggedInUser?.isAdmin}>
              <Menu data-testid="user-menu" trigger="hover">
                <Menu.Target>
                  <UnstyledButton className="user">
                    <Center>
                      <Text data-testid="user-menu">{activeYard?.firm?.name}</Text>
                      <IconChevronDown size={12} stroke={1.5} />
                    </Center>
                  </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item onClick={switchYardModal.open} leftSection={<IconSwitchHorizontal size={14} stroke={1.5} />}>
                    {t("headerText2")}
                  </Menu.Item>
                  <Menu.Item onClick={logout} color="red" leftSection={<IconLogout size={14} stroke={1.5} />}>
                    {t("headerText3")}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Conditional>
            <Conditional renderIf={isLoggedIn && !loggedInUser?.isAdmin}>
              <Menu data-testid="user-menu" trigger="hover">
                <Menu.Target>
                  <UnstyledButton className="user">
                    <Center>
                      <Text data-testid="user-menu">{activeYard?.firm?.name}</Text>
                      <IconChevronDown size={12} stroke={1.5} />
                    </Center>
                  </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item onClick={logout} color="red" leftSection={<IconLogout size={14} stroke={1.5} />}>
                    {t("headerText3")}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Conditional>
            <Menu>
              <Menu.Target>
                <ActionIcon variant="default" size={34}>
                  <Text size="sm">{i18n.language.split('-')[0].toUpperCase()}</Text>
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={() => i18n.changeLanguage('en')}>EN</Menu.Item>
                <Menu.Item onClick={() => i18n.changeLanguage('es')}>ES</Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <Conditional renderIf={!appSettings.darkMode}>
              <ActionIcon variant="default" size={34} onClick={appSettings.toggleTheme}>
                <IconMoon stroke={1} />
              </ActionIcon>
            </Conditional>
            <Conditional renderIf={appSettings.darkMode}>
              <ActionIcon variant="default" size={34} onClick={appSettings.toggleTheme}>
                <IconSun stroke={1} />
              </ActionIcon>
            </Conditional>
          </Group>
          <Group hiddenFrom="sm">
            <Burger opened={drawerOpened} onClick={toggleDrawer} />
          </Group>
        </Group>
        <Divider />
      </header>
      <AppDrawer opened={drawerOpened} onClose={toggleDrawer} openSwitchYardModal={switchYardModal.open} />
      <CustomModal size="lg" centered title={t("headerText6")} onClose={switchYardModal.close} opened={switchYardModal.isActive}>
        <Divider />
        <Space h={15} />
        <SwitchYard close={switchYardModal.close} />
        <Divider />
        <Space h={20} />
        <Button onClick={switchYardModal.close} variant="default">
          {t("headerText7")}
        </Button>
      </CustomModal>
    </>
  );
};

// const StyledHeader = styled.div`
//   .img {
//     width: 100px;
//     height: auto;
//   }

//   .inner {
//     height: 56px;
//     align-items: center;
//   }

//   .hidden-mobile {
//     @media (max-width: 576px) {
//       display: none;
//     }
//   }

//   .hiddenDesktop {
//     @media (min-width: 576px) {
//       display: none;
//     }
//   }

//   .user {
//     padding: 5px;
//     border-radius: 5px;
//     &:hover {
//       background-color: ${(props) => {
//         return props.theme.darkMode ? props.theme.colors!.dark![6] : props.theme.colors!.gray![0];
//       }};
//     }
//   }
//   .user: hover {
//     background-color: ${(props) =>
//       props.theme.darkMode ? props.theme.colors!.dark![6] : props.theme.colors!.gray![0]};
//   }
//   .user-active {
//     background-color: ${(props) =>
//       props.theme.darkMode ? props.theme.colors!.dark![6] : props.theme.colors!.gray![0]};
//   }
//   .link {
//     height: 100%;
//     padding: 7px;
//     display: "flex";
//     font-weight: 500;
//     align-items: center;
//     text-decoration: none;
//     font-size: ${(props) => props.theme.fontSizes?.sm}px;

//     border-radius: ${(props) => props.theme.radius?.sm}px;
//     color: ${(props) => (props.theme.darkMode ? props.theme.white : props.theme.black)};

//     &:hover {
//       cursor: pointer;
//       background-color: ${(props) =>
//         props.theme.darkMode ? props.theme.colors?.gray?.[8] : props.theme.colors!.green![0]};
//     }
//   }
// `;

export default AppHeader;
