const inventoryTranslation = {
  useSaveYardProductText1: "Producto guardado",
  useSaveYardProductText2: "Producto guardado exitosamente",
  useSaveYardProductText3: "Error",
  useSaveYardProductText4: "Error al guardar el producto",

  inventoryEditProductModalText1: "Editar",
  inventoryEditProductModalText2: "Precio",
  inventoryEditProductModalText3: "Se requiere un precio válido",
  inventoryEditProductModalText4: "Cantidad",
  inventoryEditProductModalText5: "Se requiere una cantidad válida",
  inventoryEditProductModalText6: "Cerrar",
  inventoryEditProductModalText7: "Guardar",
  inventoryEditProductModalText8: "No manejo este producto",
  inventoryEditProductModalText9: "Establecer disponibilidad futura",
  inventoryEditProductModalText10: "Disponible Ahora",
  inventoryEditProductModalText11: "Cancelar",

  inventoryHeaderText1: "Inventario",
  inventoryHeaderText2: "Buscar",
  inventoryHeaderText3: "Agregar Producto",

  inventoryQueryErrorText1: "Ocurrió un error al cargar su inventario, asegúrese de tener conexión a internet o contacte a Heather en",
  inventoryQueryErrorText2: "Ocurrió un error al cargar su inventario, contacte a Heather en",
  inventoryQueryErrorText3: "para asistencia.",
  inventoryQueryErrorText4: "Volver",
  inventoryQueryErrorText5: "Intentar de nuevo",

  inventoryTableWrapperText1: "Nombre Latino",
  inventoryTableWrapperText2: "Tamaño",
  inventoryTableWrapperText3: "Precio",
  inventoryTableWrapperText4: "Disponibilidad",

  inventoryProductItemText1: "Editar",
  inventoryProductItemText2: "Nombre Latino",
  inventoryProductItemText3: "Tamaño",
  inventoryProductItemText4: "Precio",
  inventoryProductItemText5: "Disponibilidad",
  inventoryProductItemText6: "Editar",

  inventoryAddProductModalText1: "Agregar Producto",
  inventoryAddProductModalText2: "Buscar y seleccionar una planta",
  inventoryAddProductModalText3: "Buscar una planta",
  inventoryAddProductModalText4: "Buscar y seleccionar un tamaño",
  inventoryAddProductModalText5: "Buscar un tamaño",
  inventoryAddProductModalText6: "Precio",
  inventoryAddProductModalText7: "Se requiere un precio válido",
  inventoryAddProductModalText8: "Cantidad",
  inventoryAddProductModalText9: "Se requiere una cantidad válida",
  inventoryAddProductModalText10: "Cerrar",
  inventoryAddProductModalText11: "Enviar",
  inventoryAddProductModalText12: "Sin opciones",
  inventoryAddProductModalText13: "Establecer disponibilidad futura",
  inventoryAddProductModalText14: "Disponible Ahora",
  inventoryAddProductModalText15: "Cancelar",
  inventoryAddProductModalText16: "Se requiere una planta válida",
  inventoryAddProductModalText17: "Se requiere un tamaño válido",

  inventoryAvailabilityText1: "Disponible",
  inventoryAvailabilityText2: "Disponible después de",

  noInventoryText1: "No tiene inventario",
  noInventoryText2: "No tiene inventario disponible. Por favor use el botón de abajo para agregar productos a su inventario.",
  noInventoryText3: "Agregar Producto",

  inventorySearchNotFount: "No se encontraron resultados",
  inventorySearchNotFountText: "No se encontraron productos para su búsqueda, intente de nuevo.",
};

export default inventoryTranslation; 