const biddingTranslations = {
  biddingText1: "Sin oferta",
  biddingText2: "Enviar",

  bidQueryErrorText1: "Error",
  bidQueryErrorText2: "Ocurrió un error al cargar su cotización, asegúrese de tener conexión a internet o contacte a Heather en",
  bidQueryErrorText3: "Ocurrió un error al cargar su cotización, contacte a Heather en",
  bidQueryErrorText4: "para asistencia.",
  bidQueryErrorText5: "Volver",
  bidQueryErrorText6: "Intentar de nuevo",

  biddingHeaderText1: "Fecha de Entrega:",
  biddingHeaderText2: "Destino:",
  biddingHeaderText3: "Abrir mapa",
  biddingHeaderText4: "¿Preguntas? Envíe un correo a",
  biddingHeaderText5: "Este pedido es para recoger en vivero.",
  biddingHeaderText6: "Fecha de recogida:",

  bidsListText1: "Sus artículos cotizados",
  bidsListText2: "Subtotal: ",

  bidsListBidItemText1: "por",
  bidsListBidItemText2: "cada uno",

  bidsListSubItemText1: "por",
  bidsListSubItemText2: "cada uno",

  categoryItemBidText1: "por",
  categoryItemBidText2: "cada uno",
  categoryItemBidText3: "Eliminar",
  categoryItemBidText4: "Nombre Latino",
  categoryItemBidText5: "Nombre(s) Común(es)",
  categoryItemBidText6: "Tamaño",
  categoryItemBidText7: "Cantidad",
  categoryItemBidText8: "Precio",
  categoryItemBidText9: "por",
  categoryItemBidText10: "cada uno",
  categoryItemBidText11: "Eliminar",

  categoryItemNoneText1: "Tengo",
  categoryItemNoneText2: "Tengo un sustituto",
  categoryItemNoneText3: "Nombre Latino",
  categoryItemNoneText4: "Nombre(s) Común(es)",
  categoryItemNoneText5: "Tamaño",
  categoryItemNoneText6: "Cantidad",
  categoryItemNoneText7: "Tengo",
  categoryItemNoneText8: "Tengo un sustituto",

  categoryItemBidFormText1: "Precio unitario",
  categoryItemBidFormText2: "Precio inválido",
  categoryItemBidFormText3: "Establecer Precio",
  categoryItemBidFormText4: "Establecer Calidad",
  categoryItemBidFormText5: "Cancelar",
  categoryItemBidFormText6: "Nombre Latino",
  categoryItemBidFormText7: "Nombre(s) Común(es)",
  categoryItemBidFormText8: "Tamaño",
  categoryItemBidFormText9: "Cantidad",
  categoryItemBidFormText10: "Precio",
  categoryItemBidFormText11: "Precio unitario",
  categoryItemBidFormText12: "Precio inválido",
  categoryItemBidFormText13: "Establecer Precio",
  categoryItemBidFormText14: "Calidad",
  categoryItemBidFormText15: "Establecer Calidad",
  categoryItemBidFormText16: "Cancelar",
  categoryItemBidFormText17: "por",
  categoryItemBidFormText18: "cada uno",

  categoryItemSubbedText1: "por",
  categoryItemSubbedText2: "cada uno",
  categoryItemSubbedText3: "Eliminar",
  categoryItemSubbedText4: "Nombre Latino",
  categoryItemSubbedText5: "Nombre(s) Común(es)",
  categoryItemSubbedText6: "Tamaño",
  categoryItemSubbedText7: "Cantidad",
  categoryItemSubbedText8: "Precio",
  categoryItemSubbedText9: "por",
  categoryItemSubbedText10: "cada uno",
  categoryItemSubbedText11: "Eliminar",

  categoryItemSubFormText0: "Precio inválido",
  categoryItemSubFormText1: "Guardar Sustituto",
  categoryItemSubFormText2: "Cancelar",
  categoryItemSubFormText3: "Nombre Latino",
  categoryItemSubFormText4: "Nombre(s) Común(es)",
  categoryItemSubFormText5: "Tamaño",
  categoryItemSubFormText6: "Precio",
  categoryItemSubFormText7: "Precio inválido",
  categoryItemSubFormText8: "Guardar Sustituto",
  categoryItemSubFormText9: "Cancelar",

  biddingTableWrapperText1: "Nombre Latino",
  biddingTableWrapperText2: "Nombre(s) Común(es)",
  biddingTableWrapperText3: "Tamaño",
  biddingTableWrapperText4: "Cantidad",
  biddingTableWrapperText5: "Precio",

  deliveryTermsText1: "Términos de Entrega para",
  deliveryTermsText2: "Sus Términos",
  deliveryTermsText3: "Tarifa:",
  deliveryTermsText4: "Pedido mínimo para entrega:",
  deliveryTermsText5: "Cargo por debajo del mínimo:",
  deliveryTermsText6: "¿Cuál es su estructura de tarifas de entrega para esta cotización?",
  deliveryTermsText7: "Tarifa Fija",
  deliveryTermsText8: "Porcentaje del Pedido",
  deliveryTermsText9: "¿Cuál es su tarifa para entregar el material vegetal cotizado en el área de entrega?",
  deliveryTermsText10: "Su tarifa de entrega",
  deliveryTermsText11: "Tarifa inválida",
  deliveryTermsText12: "Cambiar Tipo de Tarifa",
  deliveryTermsText13: "Siguiente",
  deliveryTermsText14: "¿Tiene un mínimo de entrega?",
  deliveryTermsText15: "Sí",
  deliveryTermsText16: "No",
  deliveryTermsText17: "¿Cuál es su pedido mínimo para entrega?",
  deliveryTermsText18: "Su pedido mínimo para entrega",
  deliveryTermsText19: "Volver",
  deliveryTermsText20: "Siguiente",
  deliveryTermsText21: "¿Entregará por debajo de este pedido mínimo por una tarifa fija diferente?",
  deliveryTermsText22: "Sí",
  deliveryTermsText23: "No",
  deliveryTermsText24: "¿Cuál es su cargo por entregar por debajo de su pedido mínimo?",
  deliveryTermsText25: "Cargo de entrega por debajo del mínimo",
  deliveryTermsText26: "Cargo mínimo inválido",
  deliveryTermsText27: "Volver",
  deliveryTermsText28: "Siguiente",
  deliveryTermsText29: "Revise su oferta arriba y envíe.",
  deliveryTermsText30: "Rehacer Términos de Entrega",
  deliveryTermsText31: "Enviar",
  deliveryTermsText32: "Solo Recoger",
  deliveryTermsText33: "Seleccionó Solo Recoger para esta cotización",

  useSubmitQuoteText1: "¡Guardado!",
  useSubmitQuoteText2: "cotización enviada exitosamente",
  useSubmitQuoteText3: "Error",
  useSubmitQuoteText4: "Ocurrió un error al enviar su cotización",

  biddingSuccessfulText1: "¡Su cotización ha sido enviada exitosamente!",
  biddingSuccessfulText2: "Panel",

  autoBidCategoryText1: "Oferta automática basada en su inventario anterior",
  autoBidCategoryText2: "gestionar",

  autoBidCategoryItemText1: "por",
  autoBidCategoryItemText2: "cada uno",
  autoBidCategoryItemText3: "Editar",
  autoBidCategoryItemText4: "Nombre Latino",
  autoBidCategoryItemText5: "Nombre(s) Común(es)",
  autoBidCategoryItemText6: "Tamaño",
  autoBidCategoryItemText7: "Cantidad",
  autoBidCategoryItemText8: "Precio",
  autoBidCategoryItemText9: "por",
  autoBidCategoryItemText10: "cada uno",
  autoBidCategoryItemText11: "Editar",

  editAutoBidItemText1: "Editar",
  editAutoBidItemText2: "Cambiar precio de",
  editAutoBidItemText3: "No tengo",
  editAutoBidItemText4: "No manejo este producto",
  editAutoBidItemText5: "Guardar precio",
  editAutoBidItemText6: "Cancelar",

  nurseryNetNoteText1: "Solicitud de NurseryNet.",
  nurseryNetNoteText2: "Esta es una solicitud de NurseryNet. NurseryNet es un mercado de vivero a vivero donde puede",
  nurseryNetNoteText3: "encontrar cualquier cosa",
  nurseryNetNoteText4: "desde plugs hasta árboles cultivados en campo, todo a costo directo. ¿Interesado en destacar sus propias plantas?",
  nurseryNetNoteText5: "Actualice su inventario aquí",
};

export default biddingTranslations; 