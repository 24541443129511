const wrapperTranslations = {
  footerText1: `Copyright 2015-${new Date().getFullYear()} Bidscape, Inc`,
  footerText2: "Privacy",
  footerText3: "Terms",

  headerText1: "Login",
  headerText2: "Switch yard",
  headerText3: "Logout",
  headerText4: "Navigation",
  headerText5: "Toggle theme",
  headerText6: "Switch Yard",
  headerText7: "Close",
  headerText8: "Dashboard",
  headerText9: "Manage Inventory",
  headerText10: "Switch language",

  switchYardText1: "Search yard",
};

export default wrapperTranslations;
